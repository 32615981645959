<script>
	import { fmtCur } from '$lib/numFormat.js';

	export let prop;
	export let showPrice = true;

	function getPrice(prop) {
		return `${fmtCur(prop.price, false)} or ${fmtCur(prop.lowest_monthly_payment)}/month`;
	}

	function getImage(prop) {
		if (prop.prop_images.length === 0) {
			return '/property_placeholder.png';
		}
		return `${import.meta.env.VITE_API_URL}/properties/image/${
			prop.prop_images[0].id
		}?scaley=350&encoding=webp`;
	}

	let intersecting = false;

	let propertyUrl;
	$: if (intersecting) {
		if (prop.prop_images.length > 0) {
			propertyUrl = `${import.meta.env.VITE_API_URL}/properties/image/${
				prop.prop_images[0].id
			}?scaley=350&encoding=webp`;
		} else {
			propertyUrl = '/property_placeholder.png';
		}
	}
</script>

<a href="/properties/{prop.slug}">
	<div class="land-card">
		<img src={getImage(prop)} alt="" />
		<img src="/cl-flag-logo.png" alt="" id="logo" />
		<div>
			<p id="acre">{prop.acres} ACRE</p>
			<p id="location">{prop.county}, {prop.state}</p>
			<p id="prop-id">{prop.prop_id}</p>
			<p id="price">{getPrice(prop)}</p>
		</div>
	</div>
</a>

<style>
	a {
		text-decoration: none;
		width: 750px;
		height: 500px;
		color: black;
	}

	.land-card {
		width: 100%;
		height: 500px;
		border-radius: 0.7rem;
		background-color: var(--pure-white);
		border: 1px solid #767b60;
		position: relative;
		transition: transform 300ms ease-in-out;
	}

	.land-card:hover {
		transform: translateY(-10px);
		transition: 300ms ease-in-out;
	}

	.land-card img {
		width: 100%;
		height: 300px;
		border-radius: 0.7rem 0.7rem 0 0;
	}

	.land-card div {
		border-radius: 1rem;
		text-align: center;
		padding: 1rem 0 1rem 0;
		letter-spacing: 1px;
	}

	#acre {
		margin-bottom: 0.3rem;
	}
	#location {
		margin-top: 0;
	}

	#prop-id {
		font-size: 14px !important;
	}

	#price {
		background-color: var(--primary-color);
		border-radius: 1rem;
		color: white;
		width: 65%;
		margin: 0 auto;
		height: 27px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #d7d9c7;
	}

	.sold {
		position: absolute;
		top: 0;
		right: 0;
		width: 35%;
		height: 20%;
		background-color: #000000a0;
		z-index: 2;
		color: white;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		font-weight: bold;
		font-size: 1rem;
		-webkit-clip-path: polygon(100% 0, 100% 100%, 24% 100%, 0 0);
		clip-path: polygon(100% 0, 100% 100%, 24% 100%, 0 0);
		border-top-right-radius: 20px;
	}

	.sold p {
		margin-right: 30%;
	}

	#logo {
		position: absolute;
		top: 13px;
		right: 13px;
		width: 100px;
		height: 70px;
	}

	@media only screen and (max-width: 1500px) {
		#price {
			width: 83%;
		}
	}

	@media only screen and (max-width: 1590px) {
		a {
			width: 50%;
		}
		.land-card {
			width: 100%;
		}
	}

	@media only screen and (max-width: 780px) {
		a {
			width: 70%;
		}
	}

	@media only screen and (max-width: 500px) {
		a {
			width: 100%;
		}

		#price {
			width: 85%;
		}
	}
</style>
